import { fetchUnseenCoupons } from 'features/footer/api';
import { couponStateAtom } from 'libs/recoil/atom';
import { useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';

interface FetchCouponStateOptions {
  /**
   * 呼び出しが有効かどうか
   */
  enabled: boolean;
  /**
   * API呼び出しの待機時間（ミリ秒）
   * @default 1500
   */
  delay?: number;
}

/**
 * 未読クーポンの状態を取得・更新するカスタムフック
 * @param options - フェッチのオプション
 */
export const useFetchCouponState = ({
  enabled,
  delay = 1500
}: FetchCouponStateOptions) => {
  const setCouponState = useSetRecoilState(couponStateAtom);
  const timeoutRef = useRef<ReturnType<typeof setTimeout>>();

  useEffect(() => {
    if (!enabled) return;

    const fetchData = async () => {
      try {
        const unread = await fetchUnseenCoupons();
        setCouponState({ unread, loaded: true });
      } catch (error) {
        console.error('Failed to fetch coupon state:', error);
        setCouponState({ unread: 0, loaded: true });
      }
    };

    // 既存のタイムアウトをクリア
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }

    // 新しいタイムアウトを設定
    timeoutRef.current = setTimeout(fetchData, delay);

    // クリーンアップ
    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
    };
  }, [setCouponState, delay, enabled]);
};
