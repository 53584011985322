import { MenuItemProps } from 'features/footer/components/MenuItem';
import {
  createFeatureMenuItem,
  generateBadgeState,
  generateExternalMenuItems,
  generateFeaturePath
} from 'features/footer/utils';
import { FEATURE_DIGICLUEPLUS_SIMPLE_COUPONS } from 'libs/features';
import {
  couponStateAtom,
  membershipSettingAtom,
  tenantConfigurationAtom,
  unreadNotificationAtom
} from 'libs/recoil/atom';
import {
  activeFeatureCodesState,
  activeFeaturesState
} from 'libs/recoil/selector';
import { useMemo } from 'react';
import { useNavigate } from 'react-router-dom';
import { useRecoilValue } from 'recoil';
import { useFetchCouponState } from './useFetchCouponState';

export const useFooterMenuItems = (): MenuItemProps[] => {
  const navigate = useNavigate();
  const tenantConfiguration = useRecoilValue(tenantConfigurationAtom);
  // 利用可能な機能
  const features = useRecoilValue(activeFeaturesState);
  // 利用可能な機能のID一覧
  const activeFeatureCodes = useRecoilValue(activeFeatureCodesState);
  // FIXME: 会員証機能に依存してメニューの遷移先が作られている
  const {
    page_template: { alias_code: aliasCode }
  } = useRecoilValue(membershipSettingAtom);
  // お知らせの状態
  const notificationState = useRecoilValue(unreadNotificationAtom);
  // クーポンの状態
  const couponState = useRecoilValue(couponStateAtom);

  useFetchCouponState({
    enabled:
      activeFeatureCodes.includes(FEATURE_DIGICLUEPLUS_SIMPLE_COUPONS.id) &&
      !couponState.loaded,
    delay: 1500
  });

  return useMemo(() => {
    const badges = generateBadgeState({ notificationState, couponState });
    const externalMenuItems = generateExternalMenuItems(
      tenantConfiguration.external_links
    );
    const featureMenuItems = features.map((feature) => {
      const navigatePath = generateFeaturePath({
        feature,
        context: { aliasCode }
      });
      return createFeatureMenuItem({
        feature,
        badges,
        navigate,
        navigatePath,
        throttleMs: 1500
      });
    });

    return [...externalMenuItems, ...featureMenuItems];
  }, [
    tenantConfiguration.external_links,
    features,
    aliasCode,
    notificationState,
    couponState,
    navigate
  ]);
};
