import { MenuItemProps } from 'features/footer/components/MenuItem';
import { Feature } from 'libs/features';
import { throttle } from 'lodash';
import { NavigateFunction } from 'react-router-dom';

interface CreateFeatureMenuItemOptions {
  feature: Feature;
  badges: string[];
  navigate: NavigateFunction;
  navigatePath: string;
  throttleMs?: number;
}

/**
 * フッターメニューの機能アイテムを生成する
 * @param options - メニューアイテム生成に必要なオプション
 */
export const createFeatureMenuItem = ({
  feature,
  badges,
  navigate,
  navigatePath,
  throttleMs = 1500
}: CreateFeatureMenuItemOptions): MenuItemProps => ({
  label: feature.label,
  url: feature.path,
  icon: feature.icon,
  activeIcon: feature.activeIcon,
  hasBadge: badges.includes(feature.id),
  handleClick: createThrottledNavigationHandler(
    navigate,
    navigatePath,
    throttleMs
  )
});

/**
 * スロットル制御されたナビゲーションハンドラを生成する
 */
const createThrottledNavigationHandler = (
  navigate: NavigateFunction,
  path: string,
  throttleMs: number
) =>
  throttle(() => navigate(path), throttleMs, {
    leading: true,
    trailing: false
  });
