import { Box, Flex } from '@chakra-ui/react';
import { MenuItem } from 'features/footer/components/MenuItem';
import { useFooterMenuItems } from 'features/footer/hooks';
import { FC } from 'react';

export const Footer: FC = () => {
  const menuItems = useFooterMenuItems();

  return (
    <Box w="100%" h="4.5rem" bg="white" className="c-bottom-area">
      <Flex gap="0" flex="1 0 auto">
        {menuItems.map((menuItem) => (
          <MenuItem key={menuItem.url} {...menuItem} />
        ))}
      </Flex>
    </Box>
  );
};
