import {
  FEATURE_DIGICLUEPLUS_SIMPLE_COUPONS,
  FEATURE_NOTICE
} from 'libs/features';
import { CouponState, NotificationState } from 'libs/recoil/atom';

type BadgeStateParams = {
  notificationState: NotificationState;
  couponState: CouponState;
};

/**
 * フッターメニューの未読バッジ(赤丸)表示状態を生成
 * @param notificationState - お知らせの未読状態
 * @param couponState - クーポンの未読状態
 * @returns バッジを表示するメニューIDの配列
 */
export const generateBadgeState = ({
  notificationState,
  couponState
}: BadgeStateParams): string[] => {
  const badges = [];
  if (notificationState.hasUnread) {
    badges.push(FEATURE_NOTICE.id);
  }

  if (couponState.unread > 0) {
    badges.push(FEATURE_DIGICLUEPLUS_SIMPLE_COUPONS.id);
  }
  return badges;
};
