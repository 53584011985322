import { Box, Text } from '@chakra-ui/react';
import { Icon } from 'features/footer/components/Icon';
import { FC } from 'react';
import { useLocation } from 'react-router-dom';
import { MenuItemProps } from './types';

export const MenuItem: FC<MenuItemProps> = ({
  label,
  url,
  icon,
  activeIcon,
  hasBadge,
  handleClick
}) => {
  const location = useLocation();
  // 現在のURLがアクティブかどうかを判定
  const isActiveLink = (href: string) =>
    location.pathname.split('/')[1] === href.split('/')[1];

  return (
    <Box
      as="button"
      ms="0"
      width="100%"
      flexGrow="1"
      color={isActiveLink(url) ? 'primary' : 'dark.500'}
      textAlign="center"
      height="4.5rem"
      onClick={handleClick}
    >
      <Icon
        icon={isActiveLink(url) && activeIcon ? activeIcon : icon}
        hasBadge={hasBadge}
      />
      <Text fontWeight="700">{label}</Text>
    </Box>
  );
};
