import { AlertIcon } from 'assets/icons/miscs';
import { FC } from 'react';
import { IconProps } from './types';

export const Icon: FC<IconProps> = ({ icon, hasBadge }) => {
  return (
    <span style={{ position: 'relative', display: 'inline-flex' }}>
      {hasBadge && (
        <span
          style={{
            position: 'absolute',
            left: '17px',
            top: '0px'
          }}
        >
          <AlertIcon width="11px" height="11px" position="absolute" />
        </span>
      )}
      {icon}
    </span>
  );
};
