import { UndefinedEnvironmentError } from './errors';

/**
 * パッケージAPIのベースURLを返す
 * @throws {UndefinedEnvironmentError} 環境変数が未定義の場合
 * @return {string} https://api.package.digiclueapp.(local|dev|com)
 */
export const getPackageApiBaseUrl = (): string => {
  const value = process.env.REACT_APP_PACKAGE_API_BASE_URL;
  if (!value) {
    throw new UndefinedEnvironmentError('REACT_APP_PACKAGE_API_BASE_URL');
  }

  return value;
};
