export class ApiError extends Error {
  constructor(message: string, public readonly original: unknown) {
    super(message);
    this.name = new.target.name;
  }
}

export class NetworkError extends ApiError {
  constructor(public readonly url: string, error: unknown) {
    super(`Network Error: ${url}`, error);
  }
}

export class ResponseError extends ApiError {
  public readonly status;
  public readonly statusText;

  constructor(public readonly response: Response) {
    super(`HTTP ${response.status} ${response.statusText}`, response);
    this.status = response.status;
    this.statusText = response.statusText;
  }
}

export class JsonParseError extends ApiError {
  constructor(public readonly response: Response) {
    super('Failed to parse response JSON', response);
  }
}
