import { Feature, FEATURE_MEMBERSHIP } from 'libs/features';

interface GenerateFeaturePathOptions {
  feature: Feature;
  context: FeatureContext;
}

interface FeatureContext {
  /**
   * 会員証のテンプレートコード（例：cumin）
   * 空文字の場合は通常のパスを生成
   */
  aliasCode: string;
}

/**
 * 機能へのパスを生成する
 * 会員証機能の場合、エイリアスコードを含むパスを生成
 * @param options.feature - 機能情報
 * @param options.context - パス生成に必要なコンテキスト
 * @returns 生成されたパス
 */
export const generateFeaturePath = ({
  feature,
  context
}: GenerateFeaturePathOptions): string => {
  if (isMembershipFeature(feature) && hasAliasCode(context)) {
    return generatePath(feature.path, context.aliasCode);
  }

  return feature.path;
};

const isMembershipFeature = (feature: Feature): boolean => {
  return feature.id === FEATURE_MEMBERSHIP.id;
};

const hasAliasCode = (context: FeatureContext): boolean => {
  return context.aliasCode !== '';
};

const generatePath = (basePath: string, path: string): string => {
  const normalizedPath = basePath.replace(/\/+$/, '');
  return `${normalizedPath}/${path}`;
};
