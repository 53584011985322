import { Box, Flex, Skeleton } from '@chakra-ui/react';
import Header from 'components/modules/Header';
import { Footer } from 'features/footer';
import { tenantConfigurationAtom } from 'libs/recoil/atom';
import { FC, ReactNode } from 'react';
import { Helmet } from 'react-helmet-async';
import { useRecoilValue } from 'recoil';

type MainLayoutProps = {
  header?: true;
  footer?: true;
  bg?: 'dark.50';
  pageTitle?: string;
  isLoading?: boolean;
  children?: ReactNode;
};

const MainLayout: FC<MainLayoutProps> = ({
  header,
  footer,
  bg,
  pageTitle,
  isLoading = false,
  children
}) => {
  const tenantConfiguration = useRecoilValue(tenantConfigurationAtom);

  const height = `calc(100vh${header ? ' - 2rem' : ''}${
    footer ? ' - 4.5rem' : ''
  })`;

  return (
    <>
      <Helmet>
        <title>{pageTitle ?? tenantConfiguration.name}</title>
      </Helmet>
      <Flex bg={bg} flexDir="column">
        {header && <Header />}
        <Skeleton isLoaded={!isLoading}>
          <Box
            bg={bg}
            h={height}
            pb="1.25rem"
            overflowY="scroll"
            position="relative"
          >
            {children}
          </Box>
        </Skeleton>
        {footer && <Footer />}
      </Flex>
    </>
  );
};
export default MainLayout;
