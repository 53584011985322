import { JsonParseError, NetworkError, ResponseError } from './errors';
import {
  ApiClientConfig,
  HeaderParams,
  UnseenCouponApiResponse
} from './types';

const API_PATH = '/api/digiclueplus/coupons/unseens/stats';

export class UnseenCouponsApiClient {
  private readonly baseUrl: string;

  constructor(config: ApiClientConfig) {
    this.baseUrl = config.baseUrl;
  }

  async call({ tenantCode, idToken }: HeaderParams): Promise<number> {
    const url = this.generateRequestUrl();
    const headers = this.generateRequestHeader(tenantCode, idToken);
    const response = await this.invokeApi(url, headers);
    if (!response.ok) {
      throw new ResponseError(response);
    }

    try {
      const json = (await response.json()) as UnseenCouponApiResponse;
      return json.data.couponCount;
    } catch (error) {
      throw new JsonParseError(response);
    }
  }

  private generateRequestUrl(): URL {
    return new URL(API_PATH, this.baseUrl);
  }

  private generateRequestHeader(
    tenantCode: string,
    idToken: string
  ): Record<string, string> {
    return {
      'Content-Type': 'application/json',
      'DIGICLUE-TENANT-CODE': tenantCode,
      'DIGICLUE-TOKEN-ID': idToken
    };
  }

  private async invokeApi(
    url: URL,
    headers: Record<string, string>
  ): Promise<Response> {
    try {
      // NOTE: this.fetch = config.fetch window.fetch にすると動かない...?
      return await window.fetch(url.toString(), {
        method: 'GET',
        headers: headers
      });
    } catch (error) {
      throw new NetworkError(url.toString(), error);
    }
  }
}
