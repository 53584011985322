export class LiffApiError extends Error {
  constructor(message: string) {
    super(message);
    this.name = new.target.name; // 自動的にクラス名を設定
  }
}

export class LiffGetAccessTokenError extends LiffApiError {
  constructor() {
    super('Failed to retrieve LIFF access token');
  }
}

export class LiffGetIdTokenError extends LiffApiError {
  constructor() {
    super('Failed to retrieve LIFF id-token');
  }
}
