import { Image } from '@chakra-ui/react';
import { ExternalDefaultIcon } from 'assets/icons/FooterIcons';
import { MenuItemProps } from 'features/footer/components/MenuItem';
import { openWindow } from 'libs/line';
import { LinkType } from 'types/LinkType';

/**
 * 外部遷移するリンクを生成する
 * FIXME: この機能使われている...?
 */
export const generateExternalMenuItems = (
  externalLinks: LinkType[]
): MenuItemProps[] => {
  return externalLinks.map((link) => ({
    label: link.label,
    url: link.url,
    icon: generateIcon(link),
    handleClick: () => openWindow(link.url)
  }));
};

/**
 * リンクの設定に基づいてアイコンを生成
 */
const generateIcon = (link: LinkType) => {
  if (!link.icon) {
    return <ExternalDefaultIcon boxSize="2rem" />;
  }

  return (
    <Image
      src={link.icon}
      height="2rem"
      width="100%"
      objectFit="contain"
      alt={`${link.label}のアイコン`}
    />
  );
};
