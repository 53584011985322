import liff, { Liff } from '@line/liff';
import { LiffGetIdTokenError } from './errors';

export interface GetIdTokenOptions<T extends boolean = false> {
  client?: Liff | null;
  throwable?: T;
}

/**
 * LIFF経由でID-Tokenを取得する
 * NOTE: liff.initされたときのアクセストークンとなるため、SPAの場合は注意が必要
 * throwable: true を指定した際に外から型安全で使えるようにジェネリクスを使っている
 * @returns アクセストークンまたはnull
 */
export const getIdToken = <T extends boolean>({
  client = null,
  throwable
}: GetIdTokenOptions<T>): T extends true ? string : string | null => {
  const clientInstance = client || liff;
  const idToken = clientInstance.getIDToken();

  if (idToken === null && throwable) {
    throw new LiffGetIdTokenError();
  }

  return idToken as T extends true ? string : string | null;
};
