import {
  CouponFilledIcon,
  CouponIcon,
  MembershipFilledIcon,
  MembershipIcon,
  MenuFilledIcon,
  MenuIcon,
  MystoreFilledIcon,
  MystoreIcon,
  NotificationFilledIcon,
  NotificationIcon,
  ReservationFilledIcon,
  ReservationIcon,
  StampFilledIcon,
  StampIcon
} from 'assets/icons/FooterIcons';

export type Feature = {
  id: string;
  label: string;
  path: string;
  icon: JSX.Element;
  activeIcon?: JSX.Element;
};

// FIXME: 各Fetureのメニュー設定はfeatures上で行うのが良さそう...
// その場合は type Featureは外に置く
export const FEATURE_RESERVE: Feature = {
  id: 'reserve',
  label: '予約注文',
  icon: <ReservationIcon boxSize={8} />,
  activeIcon: <ReservationFilledIcon boxSize={8} />,
  path: '/reservations'
};

export const FEATURE_STORE: Feature = {
  id: 'store',
  label: 'マイ店舗',
  icon: <MystoreIcon boxSize={8} />,
  activeIcon: <MystoreFilledIcon boxSize={8} />,
  path: '/my-store'
};

export const FEATURE_NOTICE: Feature = {
  id: 'notice',
  label: 'お知らせ',
  icon: <NotificationIcon boxSize={8} />,
  activeIcon: <NotificationFilledIcon boxSize={8} />,
  path: '/notifications'
};

// シンプルクーポンもポイントクーポンもタブのアイコンと文言は同じ
// 2023年8月末時点では、ひとつのテナントがシンプルクーポンとポイントクーポンを同時にミニアプリ上で提供することはない
export const FEATURE_DIGICLUEPLUS_SIMPLE_COUPONS: Feature = {
  id: 'digiclueplus_simple_coupons',
  label: 'クーポン',
  icon: <CouponIcon boxSize={8} />,
  activeIcon: <CouponFilledIcon boxSize={8} />,
  path: '/coupon'
};

export const FEATURE_DIGICLUEPLUS_POINT_COUPONS: Feature = {
  id: 'digiclueplus_point_coupons',
  label: 'クーポン',
  icon: <CouponIcon boxSize={8} />,
  activeIcon: <CouponFilledIcon boxSize={8} />,
  path: '/point-coupon'
};

export const FEATURE_DIGICLUEPLUS_STAMPS: Feature = {
  id: 'digiclueplus_stamps',
  label: 'キャンペーン',
  icon: <StampIcon boxSize={8} />,
  activeIcon: <StampFilledIcon boxSize={8} />,
  path: '/stamps'
};

export const FEATURE_MEMBERSHIP: Feature = {
  id: 'membership',
  label: '会員証',
  icon: <MembershipIcon boxSize={8} />,
  activeIcon: <MembershipFilledIcon boxSize={8} />,
  path: '/membership'
};

// メニューリンクを表示
export const FEATURE_LINK: Feature = {
  id: 'link',
  label: 'メニュー',
  icon: <MenuIcon boxSize={8} />,
  activeIcon: <MenuFilledIcon boxSize={8} />,
  path: '/menu'
};

/**
 * 機能一覧
 *
 * キーが識別子
 * それぞれが1つのフッターアイテムになる
 */
export const FEATURES: { [key: string]: Feature } = {
  [FEATURE_RESERVE.id]: FEATURE_RESERVE,
  [FEATURE_STORE.id]: FEATURE_STORE,
  [FEATURE_NOTICE.id]: FEATURE_NOTICE,
  [FEATURE_DIGICLUEPLUS_SIMPLE_COUPONS.id]: FEATURE_DIGICLUEPLUS_SIMPLE_COUPONS,
  [FEATURE_DIGICLUEPLUS_POINT_COUPONS.id]: FEATURE_DIGICLUEPLUS_POINT_COUPONS,
  [FEATURE_DIGICLUEPLUS_STAMPS.id]: FEATURE_DIGICLUEPLUS_STAMPS,
  [FEATURE_MEMBERSHIP.id]: FEATURE_MEMBERSHIP,
  [FEATURE_LINK.id]: FEATURE_LINK
} as const;

// 利用可能な機能一覧の識別子かどうが判定
export const isFeatureCodeActive = (code: string): boolean =>
  Object.keys(FEATURES).includes(code);
