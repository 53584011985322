import { TENANT_CODE } from 'libs/constants';
import { getPackageApiBaseUrl } from 'libs/env';
import { getIdToken } from 'libs/liff-api';
import { UnseenCouponApiParams } from './types';
import { UnseenCouponsApiClient } from './unseen-coupons-api-client';

/**
 * 未読クーポン件数を取得する
 *
 * 指定された `baseUrl`、`tenantCode`、`idToken` を使用してAPIから未読クーポン件数を取得します。
 *
 * @param {UnseenCouponApiParams} params - APIリクエストに必要なパラメータ
 * @param {string} params.baseUrl - APIのベースURL
 * @param {string} params.tenantCode - テナントコード
 * @param {string} params.idToken - IDトークン
 * @returns {Promise<number>} 未読クーポン件数
 * @throws {import('./errors').NetworkError} ネットワーク接続に失敗した場合
 * @throws {import('./errors').ResponseError} APIからエラーレスポンスを受け取った場合
 * @throws {import('./errors').JsonParseError} レスポンスのJSONパースに失敗した場合
 *
 * @example
 * ```typescript
 * const count = await fetchUnreadCouponCount({
 *   baseUrl: 'https://package.digiclueapp.com',
 *   tenantCode: 'tenant123',
 *   idToken: 'token456'
 * });
 * ```
 */
export const invokeUnseenCouponsApiClient = async ({
  baseUrl,
  tenantCode,
  idToken
}: UnseenCouponApiParams): Promise<number> => {
  const client = new UnseenCouponsApiClient({ baseUrl });
  return client.call({ tenantCode, idToken });
};

/**
 * [外部インターフェース]
 * 未読のクーポン件数を取得する
 */
export const fetchUnseenCoupons = async (): Promise<number> => {
  const baseUrl = getPackageApiBaseUrl();
  const idToken = getIdToken({ throwable: true });
  // FIXME: pathベースではなく、JWTとかにユーザー情報 + テナントコードが入っていると良さげ
  const tenantCode = TENANT_CODE;
  return invokeUnseenCouponsApiClient({ baseUrl, idToken, tenantCode });
};
